import IMask from "imask";
import * as EmailValidator from 'email-validator';

class Order {
    is_phone_correct = false // Полностью ли введен телефон.
    is_email_correct = false // Введена ли почта.
    is_name_correct = false // Введена ли почта.
    is_order_familiar = false // Ознакомился ли пользователь с содержанием заказа.
    is_policy_familiar = false // Ознакомился ли пользователь с содержанием заказа.
    is_any_ticket_selected = true //Выбран ли хотя бы один билет. Изменяется извне
    dom = {
        wrapper: null,
        button: null,
        inputs: {
            name: null,
            phone: null,
            email: null,
            promo: null,
            cert: null,
        },
        checkboxes: {
            order: null,
            policy: null,
        }
    }
    masks = {
        phone: null,
        email: null,
    }
    constructor(wrapper, button) {
        this.dom.wrapper = wrapper;
        this.dom.button = button;
        this.initDom();
        this.setMasks();
        this.setListeners();
    }
    initDom() {
        this.dom.inputs.name = this.dom.wrapper.querySelector('#info_name');
        this.dom.inputs.phone = this.dom.wrapper.querySelector('#info_phone');
        this.dom.inputs.email = this.dom.wrapper.querySelector('#info_email');
        this.dom.inputs.promo = this.dom.wrapper.querySelector('#info_promo');
        this.dom.inputs.cert = this.dom.wrapper.querySelector('#info_cert');
        this.dom.checkboxes.order = this.dom.wrapper.querySelector('#info_order_correct');
        this.dom.checkboxes.policy = this.dom.wrapper.querySelector('#info_policy');
    }
    setMasks() {
        this.masks.phone = this.dom.inputs.phone ? new IMask(this.dom.inputs.phone, {
            mask: /^\+?[\d]{0,12}$/
        }) : null;
        this.masks.email = EmailValidator.validate;

        if (this.dom.inputs.name.value !== '') {
            this.nameInputHandler();
        }
        if (this.dom.inputs.phone.value !== '') {
            this.phoneInputHandler();
        }
        if (this.dom.inputs.email.value !== '') {
            this.emailInputHandler();
        }
    }
    setListeners() {
        if (this.dom.inputs.name) {
            this.dom.inputs.name.addEventListener('input', e => {
                this.nameInputHandler();
            });
        }
        if (this.dom.inputs.phone) {
            this.dom.inputs.phone.addEventListener('input', e => {
                this.phoneInputHandler();
            });
        }
        if (this.dom.inputs.email) {
            this.dom.inputs.email.addEventListener('input', e => {
                this.emailInputHandler();
            });
        }
        if (this.dom.inputs.promo) {
            this.dom.inputs.promo.addEventListener('input', e => {
                this.promoInputHandler(e.target);
            });
        }
        if (this.dom.inputs.cert) {
            this.dom.inputs.cert.addEventListener('input', e => {
                this.promoInputHandler(e.target);
            });
        }
        if (this.dom.checkboxes.order) {
            this.dom.checkboxes.order.addEventListener('change', e => {
                this.checkboxesChecked(e.target);
            });
        }
        if (this.dom.checkboxes.policy) {
            this.dom.checkboxes.policy.addEventListener('change', e => {
                this.checkboxesChecked(e.target);
            });
        }
    }
    update() {
        if (this._isAllFieldsCorrect()) {
            this.enable(this.dom.button);
        } else {
            this.disable(this.dom.button);
        }
    }
    enable(button) {
        button.classList.remove('disabled');
    }
    disable(button) {
        button.classList.add('disabled');
    }
    enabled() {
        return !this.disabled();
    }
    disabled() {
        return this.dom.button.classList.contains('disabled');
    }
    phoneInputHandler() {
        this.is_phone_correct = this.masks.phone.unmaskedValue.length >= 10;

        this.dom.inputs.phone.dataset['valid'] = this.is_phone_correct;

        this.update();
    }
    emailInputHandler() {
        this.is_email_correct = this.masks.email(this.dom.inputs.email.value);

        this.dom.inputs.email.dataset['valid'] = this.is_email_correct;

        this.update();
    }
    nameInputHandler() {
        this.is_name_correct = this.dom.inputs.name.value.length > 2;

        this.dom.inputs.name.dataset['valid'] = this.is_name_correct;

        this.update();
    }
    promoInputHandler(input) {
        const button = input.nextElementSibling;

        if(input.value.length > 5) {
            this.enable(button);
        } else {
            this.disable(button);
        }
    }
    checkboxesChecked(checkbox) {
        const param = checkbox.id === this.dom.checkboxes.policy.id
            ? 'is_policy_familiar'
            : 'is_order_familiar';

        this[param] = checkbox.checked;

        this.update();
    }
    _isAllFieldsCorrect() {
        this.is_order_familiar = this.dom.checkboxes.order
            ? this.dom.checkboxes.order.checked
            : this.is_order_familiar = true;

        this.is_policy_familiar = this.dom.checkboxes.policy
            ? this.dom.checkboxes.policy.checked
            : this.is_order_familiar = true;

        return this.is_name_correct
            && this.is_phone_correct
            && this.is_email_correct
            && this.is_policy_familiar
            && this.is_order_familiar
            && this.is_any_ticket_selected;
    }
    getData() {
        return {
            payer: {
                name: this.dom.inputs.name.value,
                phone: this.dom.inputs.phone.value,
                email: this.dom.inputs.email.value,
                passenger_type_id: 1,
            },
            promo: this.dom.inputs.promo.value,
            certificate: this.dom.inputs.cert.value
        }
    }
}

export default Order;