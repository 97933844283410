require("./bootstrap");
import api from "./api";
import tours from "./tours";

let search = {
    params: new URLSearchParams(window.location.search),
    panel: {
        dom: {
            stops_select: document.getElementById('stops__select'),
        },
        init: function () {
            this.filters.init();
            api.stops.list(this.loadStops.bind(this));
            document.querySelector('#search-date__input').value = search.getDefaultDate().toString('dd.MM.yyyy');
            this.setListeners();
        },
        setListeners: function () {
            document.querySelector('#search-date__input').addEventListener('change', e => {
                search.tours.load();
            })
        },
        loadStops: function (stops) {
            this.dom.stops_select.innerHTML = '';
            this.dom.stops_select.appendChild(new Option("Все места отправления", "*"));
            stops.forEach(stop => {
                this.dom.stops_select.appendChild(new Option(stop.title_ru, stop.id));
            });
            if (search.params.get('origin_id')) {
                this.dom.stops_select.value = search.params.get('origin_id');
            }
            if (document.querySelector('#filter__btn')) {
                document.querySelector('#filter__btn')
                    .addEventListener('click', e => {
                        window.location.href = this.getSearchLink();
                        e.preventDefault();
                    })
            }
        },
        getSearchLink() {
            let q = new URLSearchParams();

            if (this.date()) {
                q.append('departure', this.date().toString('yyyy-MM-dd'));
            }

            if (this.dom.stops_select.value !== '*') {
                q.append('origin_id', this.dom.stops_select.value);
            }

            document.querySelectorAll('.index-search-category').forEach(el => {
                if (el.classList.contains('active')) {
                    if (el.dataset.id !== "all") {
                        q.append('filters[]', el.dataset.id);
                    }
                }
            })

            return '/search?' + q.toString();
        },
        filters: {
            class: "index-search-category",
            parent_class: "",
            active_class: "active",
            id_postfix: '__filter',
            init: function () {
                if (this.active().length === 0) {
                    this.refresh();
                }
                this.setListeners();
            },
            setListeners() {
                document.querySelector('.' + this.class)
                    .closest('.row')
                    .addEventListener('click', e => {
                        let item = e.target.closest('.' + this.class);
                        if (item) {
                            this.toggle(item.dataset['id'])
                            search.tours.apply(this.conditions());
                        }
                    });
                document.querySelector('#stops__select')
                    .addEventListener('change', e => {
                        search.tours.apply(this.conditions());
                    });
            },
            activate: function (id) {
                if (id === 'all' && this.active().length > 0) {
                    this.refresh();
                }

                document.getElementById(id + this.id_postfix).classList.add(this.active_class);
                if (this.active().length > 1 && this.isActive('all')) {
                    this.deactivate('all');
                }
            },
            deactivate: function (id = 'all') {
                document.getElementById(id + this.id_postfix).classList.remove(this.active_class);
                if (this.active().length === 0) {
                    this.activate('all');
                }
            },
            toggle: function (id) {
                if (this.isActive(id)) {
                    this.deactivate(id)
                } else {
                    this.activate(id)
                }
            },
            refresh: function () {
                this.all().forEach(item => {
                    item.classList.remove(this.active_class);
                });
                this.activate('all');
            },
            isActive: function (id) {
                return document.getElementById(id + this.id_postfix).classList.contains(this.active_class);
            },
            active: function () {
                return document.querySelectorAll('.' + this.class + '.' + this.active_class);
            },
            all: function () {
                return document.querySelectorAll('.' + this.class);
            },
            conditions: function () {
                let conditions = [{}, {}];

                this.active().forEach(element => {
                    let t_conditions = JSON.parse(element.dataset.conditions)
                    for (let key in t_conditions) {
                        switch (typeof conditions[0][key]) {
                            case 'undefined':
                                conditions[0][key] = t_conditions[key];
                                break;
                            case 'object':
                                if (Array.isArray(t_conditions[key])) {
                                    conditions[0][key] = conditions[0][key].concat(t_conditions[key]);
                                } else {
                                    conditions[0][key].push(t_conditions[key]);
                                }
                            default:
                                conditions[0][key] = t_conditions[key];
                                break;
                        }
                    }

                    let origin_id = document.querySelector('#stops__select').value;

                    if (origin_id !== '*') {
                        conditions[1]['origin.id'] = origin_id;
                    }

                });

                return conditions;
            }
        },
        date: function () {
            let date_input = document.querySelector('#search-date__input');
            if (date_input) {
                return Date.fromDotString(date_input.value);
            }

            return null;
        }
    },

    tours: tours,

    init: function () {
        if (!document.querySelector('.index-search')) {
            return false;
        }
        this.panel.init();
        this.tours.init();
        return true;
    },
    getDefaultDate() {
        return this.params.get('departure')
            ? Date.parse(this.params.get('departure'))
            : Date.today();
    }
}

window.search = search;

search.init();