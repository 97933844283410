import Swiper, { Navigation, FreeMode, Thumbs } from "swiper";
require("./bootstrap");
import api from "./api";
import Order from './order';

class Tour {
    hash = (Math.random() + 1).toString(36).md5()
    line_id = null
    params = new URLSearchParams(window.location.search)
    dom = {
        element: null,
        title: null,
        update_triggers: null,
        available_seats: null,
        journey_category: null,
        seat_category_title: null,
        journey_category_title: null,
        seat_category: null,
        departure_date: null,
        departure_time: null,
        journey: null,
        vehicle_title: null,
        schedule: null,
        prices_wrapper: null,
        total_price: null,
        origin: null,
        destination: null,
        duration: null,
        button: null,
    }
    price_template = ''
    journeys = []
    current = {
        prices: [],
        filtered_journeys: []
    }
    order = null
    dates_updated = false
    constructor(selector_or_element, line_id) {
        if (!line_id && this.params.get('line_id')) {
            line_id = this.params.get('line_id');
        }
        if (typeof selector_or_element === 'string') {
            this.dom.element = document.querySelector(selector_or_element);
        } else if (
            selector_or_element instanceof Element
            || selector_or_element instanceof HTMLDocument
        ) {
            this.dom.element = selector_or_element;
        }

        if (!this.dom.element) {
            //TODO: uncomment line below, remove next line
            // return;
            throw new Error('Argument "selector_or_element" must be instance of Element|HTMLDocument or correct selector string');
        }

        this.line_id = line_id;
        this.initDom();
        this.load();
        this.setListeners();
        window.tour = this;
    }
    initDom() {
        this.dom.update_triggers = this.dom.element.querySelectorAll('.update__trigger');
        this.dom.title = this.dom.element.querySelector('.tour__title');
        this.dom.available_seats = this.dom.element.querySelector('.available-seats__counter');
        this.dom.journey_category = this.dom.element.querySelector('#journey_category');
        this.dom.seat_category = this.dom.element.querySelector('#seat_category');
        this.dom.journey_category_title = this.dom.element.querySelector('.journey-category__title');
        this.dom.seat_category_title = this.dom.element.querySelector('.seat-category__title');
        this.dom.departure_date = this.dom.element.querySelector('#departure_date');
        this.dom.departure_time = this.dom.element.querySelector('#departure_time');
        this.dom.departure_date_text = this.dom.element.querySelector('.departure__date');
        this.dom.departure_time_text = this.dom.element.querySelector('.departure__time');
        this.dom.journey = this.dom.element.querySelector('#journey__input');
        this.dom.vehicle_title = this.dom.element.querySelector('.vehicle__title');
        this.dom.origin = this.dom.element.querySelector('.origin__address');
        this.dom.destination = this.dom.element.querySelector('.destination__address');
        this.dom.schedule = this.dom.element.querySelector('.tour__schedule');
        this.dom.prices_wrapper = this.dom.element.querySelector('#price-counter__wrapper');
        this.dom.total_price = this.dom.element.querySelector('#total-price');
        this.dom.duration = this.dom.element.querySelector('.duration');
        this.dom.button = this.dom.element.querySelector('.tour-order__btn');
        this.dom.order_button = this.dom.element.querySelector('.final-order__button');

        if (!this.dom.departure_date.value) {
            this.dom.departure_date.value = this.getDefaultDate().toString('dd.MM.yyyy');
        }

        this.price_template = this.dom.element.querySelector('#price-counter__template').innerHTML;

        if (this.dom.element && this.dom.order_button) {
            this.order = new Order(this.dom.element, this.dom.order_button);
        }

        if (!this.dom.departure_date._flatpickr) {
            system.initFlatpickr(this.dom.departure_date);
        }
    }
    load(date = null) {
        date = date || this.date();
        if (date === null) {
            return false;
        }

        if (date instanceof Date) {
            date = date.toString('yyyy-MM-dd');
        }

        let params = this.getJourneyListParams();
        params.date_from = date;

        console.log(params);
        api.journey.list(this.loadJourneys.bind(this), params);
    }
    getJourneyListParams() {
        return {
            filters: {
                field: {
                    line_id: this.line_id
                }
            },
            order: 'departure',
            detailed: true,
            not_cached:true,
        }
    }
    updateDatesSettings(dates) {
        this.dates_updated = true;
        this.enableFlatpickrDates(dates);
        if (this.journeys.length === 0) {
            this.correctJourneyLoading(dates)
        }
    }
    correctJourneyLoading(dates = []) {
        console.log('reloaded', dates);
        if (dates.length === 0) {
            this.showNoJourneysBanners();
            return;
        }

        this.dom.departure_date._flatpickr.setDate(dates[0].date, true, 'Y-m-d');
        this.load();

    }
    enableFlatpickrDates(dates) {
        console.log('enable', dates);
        this.dom.departure_date._flatpickr.set('enable', dates.map(i => Date.parse(i.date)))
    }
    showNoJourneysBanners() {
        let f1 = document.querySelector('div.tour-buy__form');
        let f2 = document.querySelector('#ticket-buy_form');
        let m = document.querySelector('.tour-buy__menu-info');

        if (f1) {
            f1.outerHTML =
                `<div class="tour-buy__form" style=" background: transparent;">
                    <span class="tour-buy__form-header-text" style="color: #fff;">
                        К сожалению, у данного тура в ближайшее время нет запланированных рейсов.
                    </span>
                    <br/>
                    <span style="color: #fff;">
                        Вы можете также посмотреть <a href="/search" style="text-decoration: underline">другие</a> наши круизы.
                    </span>
                </div>`;
        }

        if (f2) {
            document.querySelector('#ticket-buy_form').innerHTML =
                `<span class="tour-buy__form-header-text">
                    К сожалению, у данного тура в ближайшее время нет запланированных рейсов.
                </span>
                <br/>
                <span>
                    Вы можете также посмотреть <a href="/search" style="text-decoration: underline">другие</a> наши круизы.
                </span>`;
        }

        if (m) {
            m.style.display = 'none';
        }
    }
    setListeners() {
        this.dom.update_triggers.forEach(element => {
            element.addEventListener('change', e => {
                this.update();
            });
        });

        this.dom.departure_date.addEventListener('change', e => {
            this.load();
        });

        if (this.dom.button) {
            this.dom.button.addEventListener('click', e => {
                let journey = this.journey();

                if (!journey) {
                    system.alert('_whoops');
                    return;
                }

                journey.query = {}
                journey.query.prices = this.getSelectedPrices();
                journey.query.journey_category = this.dom.journey_category.value;
                journey.query.seat_category = this.dom.seat_category.value;

                window.location.href = system.generateJourneyLink(journey, false);
            });
        }

        if (this.order && this.dom.order_button) {
            this.dom.order_button.addEventListener('click', e => {
                if (this.order.enabled()) {
                    this.sendForm();
                }
            });
        }
    }
    date() {
        let date = Date.fromDotString(this.dom.departure_date.value);

        return date ? date : null;
    }
    time() {
        let time = this.dom.departure_date.value;

        return time ? this.dom.departure_time.value : null;
    }
    journey() {
        let id = parseInt(this.dom.departure_time.value);
        let journey = null;

        if (!id) {
            return null;
        }

        journey = this.journeys.find(t_journey => parseInt(t_journey.id) === id);

        if (journey && !this.current.filtered_journeys.includes(journey.id)) {
            // this.current.filtered_journeys.push(journey.id);

            if (this.seatCategoriesHasDuplicates(journey.seat_categories)) {
                api.seat.list(
                    this.filterSeatCategories.bind(this),
                    journey.id,
                    true
                );
            }
        }

        return journey;
    }
    filterSeatCategories(seats) {
        let id = parseInt(this.dom.departure_time.value);

        if (!id) {
            return;
        }

        let available_seat_category_ids = this.getAvailableSeatCategoryIds(seats);

        if (available_seat_category_ids.length === 0) {
            return;
        }

        for (let i in this.journeys) {
            if (this.journeys[i].id !== id) {
                continue;
            }

            let seat_categories = [];

            this.journeys[i].seat_categories.forEach(seat_category => {
                if (available_seat_category_ids.includes(seat_category.id)) {
                    seat_categories.push(seat_category);
                }
            })

            if (this.seatCategoriesHasDuplicates(seat_categories)) {
                seat_categories = this.seatCategoriesRemove(seat_categories);
            }

            this.journeys[i].seat_categories = seat_categories;
        }

        let journey = this.journey();

        this.loadSeatCategories(journey.seat_categories);
        this.loadPrices(
            this.filterLoadablePrices(journey.prices),
            journey.passenger_types,
        );
    }
    seatCategoriesRemove(seat_categories) {
        let tmp_categories = [];

        seat_categories.reverse().forEach(seat_category => {
            if (!tmp_categories.find(tmp_seat_category => tmp_seat_category.id === seat_category.id)) {
                tmp_categories.push(seat_category);
            }
        })

        return tmp_categories.reverse();
    }
    getAvailableSeatCategoryIds(seats) {
        let ids = [];

        seats.forEach(seat => {
            if (!seat.seat_category || ids.includes(seat.seat_category)) {
                return;
            }

            ids.push(seat.seat_category);
        })

        return ids;
    }
    api = api
    seatCategoriesHasDuplicates(seat_categories) {
        let stack = [];
        let has_duplicate = false;

        seat_categories.forEach(seat_category => {
            if (stack.includes(seat_category.title_ru)) {
                has_duplicate = true;
                return true;
            }
            stack.push(seat_category.title_ru);
        });

        return has_duplicate;
    }
    filterJourneyCategories() {
        for (let i in this.journeys) {
            let journey_categories = [];

            this.journeys[i].journey_categories.forEach(journey_category => {
                if (this.journeys[i].prices.find(price => price.journey_category === journey_category.id)) {
                    journey_categories.push(journey_category);
                }
            })

            this.journeys[i].journey_categories = journey_categories;
        }
    }
    update() {
        this.loadTime();

        let journey = this.journey();
        if (!journey) {
            return;
        }

        this.loadJourneyCategories(journey.journey_categories);
        this.loadSeatCategories(journey.seat_categories);
        this.loadPrices(
            this.filterLoadablePrices(journey.prices),
            journey.passenger_types,
        );
        this.setText(this.dom.title, journey.aggregator_data.serviceName);
        this.setText(this.dom.available_seats, journey.available_seats);
        this.setText(this.dom.vehicle_title, journey.aggregator_data.venueName);
        this.setText(this.dom.origin, journey.origin.title_ru);
        this.setText(this.dom.destination, journey.destination.title_ru);
        this.setText(
            this.dom.journey_category_title,
            this.dom.journey_category.querySelector('option:checked').innerHTML
        )
        this.setText(
            this.dom.seat_category_title,
            this.dom.seat_category.querySelector('option:checked').innerHTML
        )
        this.setText(
            this.dom.departure_date_text,
            this.date().toString('dd.MM.yyyy')
        )
        this.setText(
            this.dom.departure_time_text,
            this.dom.departure_time.querySelector('option:checked').innerHTML
        )
        this.setText(
            this.dom.duration,
            journey.duration === 0 ? '-' : Date.today().addSeconds(journey.duration).toString('HH:mm')
        );
        this.loadLineData();

        this.updateTotalPrice();
    }
    line() {
        return system.getLine(this.journey.line_id);
    }
    loadLineData() {
        let line = this.line();

        if (!line) {
            return;
        }

        this.setText(this.dom.title, line.pagetitle);
    }
    loadJourneys(journeys) {
        console.log(journeys);
        this.journeys = journeys;

        if (!this.dates_updated) {
            api.journey.dates(this.updateDatesSettings.bind(this), {
                filters: {
                    field: {
                        line_id: this.line_id
                    }
                },
            });
        }

        this.filterJourneyCategories();

        if (this.journeys.length === 0) {
            return;
        }

        this.clear();
        this.update();
    }
    loadJourneyCategories(journey_categories) {
        let value = this.dom.journey_category.value;
        if (!value && this.params.has('journey_category')) {
            value = this.params.get('journey_category');
            this.params.delete('journey_category');
        }

        let has_value = false;

        this.dom.journey_category.innerHTML = '';

        journey_categories.forEach(journey_category => {
            this.dom.journey_category.append(new Option(
                journey_category.title_ru,
                journey_category.id
            ));

            if (parseInt(value) === parseInt(journey_category.id)) {
                has_value = true;
            }
        });

        if (value && has_value) {
            this.dom.journey_category.value = value;
        }
    }
    loadSeatCategories(seat_categories) {
        let value = this.dom.seat_category.value;
        if (!value && this.params.has('seat_category')) {
            value = this.params.get('seat_category');
            this.params.delete('seat_category');
        }

        let has_value = false;

        this.dom.seat_category.innerHTML = ''

        seat_categories.forEach(seat_category => {
            this.dom.seat_category.append(new Option(
                seat_category.title_ru,
                seat_category.id
            ));

            if (parseInt(value) === parseInt(seat_category.id)) {
                has_value = true;
            }
        });

        if (value && has_value) {
            this.dom.seat_category.value = value;
        }
    }
    loadTime() {
        let value = parseInt(this.dom.departure_time.value);
        let has_value = false;

        if (!value && this.params.get('journey_id')) {
            value = parseInt(this.params.get('journey_id'));
            this.params.delete('journey_id');
        }

        this.dom.departure_time.innerHTML = '';

        this.journeys.forEach(journey => {
            this.dom.departure_time.append(new Option(
                Date.parse(journey.departure).toString('HH:mm'),
                journey.id
            ))

            if (value === parseInt(journey.id)) {
                has_value = true;
            }
        });

        if (value && has_value) {
            this.dom.departure_time.value = value;
        }
    }
    filterLoadablePrices(prices) {
        let loadable_prices = [];
        let seat_category = parseInt(this.dom.seat_category.value);
        let journey_category = parseInt(this.dom.journey_category.value);

        prices.forEach(price => {
            if (
                (
                    seat_category === parseInt(price.seat_category)
                    || price.seat_category === null
                )
                && journey_category === parseInt(price.journey_category)
            ) {
                loadable_prices.push(price);
            }
        });
        return loadable_prices;
    }
    getTicketTouristCount(seat_category) {
        if (!seat_category) {
            return 0;
        }
        return parseInt(seat_category.title_ru.replace(/^\D+/g, ''));
    }
    loadPrices(prices, passenger_types) {
        prices = this.fillOldPriceCounters(prices, this.current.prices);
        this.clearPrices();
        let tourist_count = this.getTicketTouristCount(
            this.journey().seat_categories.find(category => parseInt(category.id) === parseInt(this.dom.seat_category.value))
        );

        prices.reverse().forEach(price => {
            if ([23,24].includes(parseInt(price.passenger_type_id))) {
                return;
            }

            let element = document.createElement('div');
            this.dom.prices_wrapper.prepend(element);
            this.dom.prices_wrapper.firstElementChild.outerHTML = this.price_template;

            let row = this.dom.prices_wrapper.firstElementChild;

            let passenger_type = passenger_types.find(type => type.id === price.passenger_type_id);

            row.querySelector('.passenger-type__title').innerHTML
                = passenger_type ? passenger_type.title_ru + (
                tourist_count > 0 ? ' (' + tourist_count + ' чел.)' : ''
            ) : 'Неизвестный';

            row.querySelector('.price__counter').innerHTML = price.count || '0';
            row.dataset.id = price.id;

            let counter = this.params.get('prices[' + price.id + ']');
            if (counter) {
                this.getPriceCounterElement(price.id).innerHTML = counter;
            } else if (this.current.prices.length === 0 && parseInt(passenger_type.id) === 1) {
                this.getPriceCounterElement(price.id).innerHTML = 1
            }
        });

        this.current.prices = this.getAdvancedSelectedPrices();

        this.setPriceCounterListeners();
    }
    fillOldPriceCounters(prices, old_prices) {
        prices.map(price => {
            let old_price = old_prices.find(
                o_price => price.passenger_type_id === o_price.passenger_type_id
            );

            price.count = old_price ? (old_price.count || 0) : 0;

            return price;
        });

        return prices;
    }
    setPriceCounterListeners() {
        document.querySelectorAll('.price-counter__btn').forEach(element => {
            element.addEventListener('click', e => {
                let price_id = e.target.closest('.price-counter__container').dataset.id;

                switch (e.target.dataset.action) {
                    case 'increment': this.incrementPrice(price_id); break;
                    case 'decrement': this.decrementPrice(price_id); break;
                }
                this.updateTotalPrice();
            });
        })
    }
    incrementPrice(price_id) {
        let counter = this.getPriceCounter(price_id);

        if (this.getSelectedPricesCounter() >= this.journey().available_seats) {
            system.alert('_overbooking_attempt');
            return false;
        }

        this.getPriceCounterElement(price_id).innerHTML = ++counter;
    }
    decrementPrice(price_id) {
        let counter = this.getPriceCounter(price_id);

        if (counter < 1) {
            return false;
        }

        this.getPriceCounterElement(price_id).innerHTML = --counter;
    }
    getPriceElement(price_id) {
        return this.dom.prices_wrapper.querySelector('[data-id="' + price_id + '"]');
    }
    getPriceCounterElement(price_id) {
        let el = this.getPriceElement(price_id);
        return el ? el.querySelector('.price__counter') : null;
    }
    getPriceCounter(price_id) {
        let el = this.getPriceCounterElement(price_id);
        return parseInt(el ? el.innerHTML : 0);
    }
    getSelectedPrices() {
        let prices = {};

        this.getAdvancedSelectedPrices().forEach(price => {
            prices[price.id] = price.count;
        })

        return prices;
    }
    getAdvancedSelectedPrices() {
        let journey = this.journey();
        let prices = [];

        if (journey) {
            this.filterLoadablePrices(journey.prices).forEach(price => {
                price.count = this.getPriceCounter(price.id);
                prices.push(price);
            })
        }

        return prices;
    }
    getSelectedPricesCounter() {
        let counter = 0;
        let prices = this.getSelectedPrices();

        for (let id in prices) {
            counter += prices[id];
        }

        return counter;
    }
    clearPrices() {
        this.dom.prices_wrapper.querySelectorAll('.price-counter__container').forEach(element => {
            element.outerHTML = '';
        });
    }
    updateTotalPrice() {
        let total = 0;
        let journey = this.journey();

        if (journey) {
            this.filterLoadablePrices(journey.prices).forEach(price => {
                total += price.price * this.getPriceCounter(price.id);
            })
        }

        if (this.order) {
            this.order.is_any_ticket_selected = total !== 0;
            this.order.update();
        }

        this.dom.total_price.innerHTML = total;
        this.current.prices = this.getAdvancedSelectedPrices();
    }
    clear() {
        this.dom.departure_time.innerHTML = '';
        this.dom.journey_category.innerHTML = '';
        this.dom.seat_category.innerHTML = '';
        this.dom.available_seats.innerHTML = '';
        this.clearPrices();
    }
    initSlider() {
        new Swiper('.gallery__slider', {
            spaceBetween: 10,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            thumbs: {
                swiper: new Swiper('.gallery__thumb-slider', {
                    spaceBetween: 10,
                    slidesPerView: 4,
                    freeMode: true,
                    watchSlidesProgress: true,
                    modules: [ Navigation, FreeMode, Thumbs ],
                }),
            },
            modules: [ Navigation, FreeMode, Thumbs ],
        });
    }
    setText(element, text) {
        if (!element) {
            return;
        }

        element.innerHTML = text;
    }
    getDefaultDate() {
        return this.params.get('departure')
            ? Date.parse(this.params.get('departure'))
            : Date.today();
    }
    getData() {
        let journey = this.journey();

        if (!this.journey()) {
            return {};
        }

        return {
            hash: this.hash,
            journey_id: journey.id,
        }
    }
    sendForm() {
        system.tmp = {};
        system.tmp.data = {
            ...this.getData(),
            ...this.order.getData(),
        };
        console.log(system.tmp.data);
        api.seat.list(this.processSeatsAndSend.bind(this), system.tmp.data.journey_id);
    }
    processSeatsAndSend(seats) {
        system.tmp.data.tickets = this.getSelectedSeatTickets(seats);
        api.order.register(this.registerOrderInLocal.bind(this), system.tmp.data);
    }
    registerOrderInLocal(order) {
        order.journey = this.journey();
        api.modx.order.register(system.pay.bind(system), order);
    }
    getSelectedSeatTickets(all_seats) {
        let prices = this.getSelectedPrices();
        let journey = this.journey();
        let used_seats = [];
        let tickets = [];

        for (let id in prices) {
            let price = journey.prices.find(t_price => parseInt(t_price.id) === parseInt(id));
            let available_seats = this.getSeatsForPrice(all_seats, price);

            for (let i=0; i < prices[id]; i++) {
                let seat = available_seats.find(t_seat => !used_seats.includes(t_seat.id));
                if (!seat) {
                    system.alert('_not_enough_seats');
                    return [];
                }
                used_seats.push(seat.id);
                tickets.push(this.getTicket(price, seat.id, system.tmp.data.payer));
            }
        }
        return tickets;
    }
    getSeatsForPrice(seats, price) {
        let available_seats = [];
        seats.forEach(seat => {
            if (
                seat.status.slug === 'available'
                && (
                    !seat.seat_category
                    || parseInt(seat.seat_category) === parseInt(price.seat_category)
                    || !seat.seat_category
                )
            ) {
                available_seats.push(seat);
            }
        })
        return available_seats;
    }
    getTicket(price, seat_id, passenger) {
        return {
            journey_id: price.journey,
            price_id: price.id,
            journey_category_id: price.journey_category,
            seat_category_id: price.seat_category ?? 0,
            seat_id: seat_id,
            passenger: {
                ...passenger,
                is_resident: 1,
                passenger_type_id: price.passenger_type_id
            }
        }
    }
}

export default Tour;
