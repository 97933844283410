import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru";
import _alert from "./alert";
import api from "./api";

let system = {
    tinkoffTerminalKey: '1655975726174',
    tinkoffCompanyEmail: 'manager@turotdel.com',
    tinkoffTaxation: 'usn_income_outcome',
    tinkoffPaymentMethod: 'full_payment',
    tinkoffPaymentObject: 'payment',
    tinkoffTax: 'none',
    init: function () {

    },
    initFlatpickr(selector_or_element) {
        let config = {
            locale: Russian,
            enableTime: false,
            dateFormat: 'd.m.Y',
            minDate: 'today'
        }

        if (typeof selector_or_element === "string") {
            document.querySelectorAll(selector_or_element).forEach(e => {
                if (!e._flatpickr) {
                    flatpickr(e, config);
                }
            });
            return;
        }

        if (!selector_or_element._flatpickr) {
            flatpickr(selector_or_element, config);
        }
    },
    alert(message, data = {}, duration = 3) {
        try {
            data.message = message;
            api.modx.alert(data);
        } catch (err) {}
        _alert.show(message, duration);
    },
    getLine(line_id) {
        if (typeof(local_lines) === "undefined" && local_lines === null) {
            return null;
        }

        return local_lines.data.find(item => {
            return parseInt(item.line_id) === parseInt(line_id)
        });
    },
    generateJourneyLink(journey, fullChain = true) {
        if (fullChain) {
            let line = this.getLine(journey.line_id);

            if (!line) {
                return this.generateJourneyLink(journey, false);
            }

            return '/' + line.uri + this.getQuery(journey);
        } else {
            return '/order' + this.getQuery(journey);
        }
    },
    getQuery(journey) {
        let q = new URLSearchParams();
        q.append('line_id', journey.line_id);
        q.append('journey_id', journey.id);
        q.append('departure', journey.departure);

        if (journey.query) {
            for (let name in journey.query) {
                if (typeof journey.query[name] === 'object') {
                    if (Array.isArray(journey.query[name])) {
                        journey.query[name].forEach(item => {
                            q.append(name + '[]', item);
                        });
                    } else {
                        for (let name2 in journey.query[name]) {
                            q.append(name + '[' + name2 + ']', journey.query[name][name2]);
                        }
                    }
                } else {
                    q.append(name, journey.query[name]);
                }
            }
        }

        return '?' + q.toString();
    },
    getPrettyDate(date) {
        let months = {
            1: 'Янв.',
            2: 'Фев.',
            3: 'Марта',
            4: 'Апр.',
            5: 'Мая',
            6: 'Июня',
            7: 'Июля',
            8: 'Авг.',
            9: 'Сент.',
            10: 'Окт.',
            11: 'Нояб.',
            12: 'Дек.',
        }

        return date.toString('dd') + ' ' + months[parseInt(date.toString('M'))];
    },
    pay(order) {
        let form = document.createElement('form');

        console.log(order);

        let data = {
            terminalkey: this.tinkoffTerminalKey,
            frame: 'false',
            language: 'ru',
            name: order.payer.name,
            email: order.payer.email,
            phone: order.payer.phone,
            order: order.number,
            amount: 0,
            description: order.journey.aggregator_data.serviceName,
            receipt: JSON.stringify(this.generateTinkoffReceiptValue(order))
        }

        order.tickets.forEach(ticket => {
            data.amount += parseInt(ticket.price);
        });

        for (let key in data) {
            let input = document.createElement('input');

            input.type = 'text';
            input.name = key;
            input.value = data[key];
            form.appendChild(input);
        }
        let appendedForm = document.querySelector('body').appendChild(form);

        if (typeof(ym) !== 'undefined' && ym !== null) {
            ym(93805331,'reachGoal','redirect_to_payment_system');
        }

        pay(appendedForm);

    },
    generateTinkoffReceiptValue(order) {
        return {
            "Email": order.payer.email,
            "Phone": order.payer.phone,
            "EmailCompany": this.tinkoffCompanyEmail,
            "Taxation": this.tinkoffTaxation,
            "Items": this.generateTinkoffOrderItems(order)
        }
    },
    generateTinkoffOrderItems(order) {
        let items = [];

        order.tickets.forEach(ticket => {
            items.push({
                "Name": ticket.journey.aggregator_data.eventName + ' ' + ticket.passenger_type.title_ru,
                "Price": ticket.price * 100,
                "Quantity": 1.00,
                "Amount": ticket.price * 100,
                "PaymentMethod": this.tinkoffPaymentMethod,
                "PaymentObject": this.tinkoffPaymentObject,
                "Tax": this.tinkoffTax
            })
        })

        console.log(items);

        return items;
    },
    getVehicleSlug(name) {
        return /[A-Za-zА-Яа-яЁё\s]+/
            .exec(name)[0]
            .toLowerCase()
            .replace(/\s+/g, '-')
    }
}

export default system;