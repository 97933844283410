const _alert = {
    locale: 'ru',
    langs: {
        ru: {
            _whoops: 'Что-то пошло не так.',
            _tour_not_found: 'Тур не найден.',
            _overbooking_attempt: 'Вы пытаетесь забронировать больше мест, чем есть.',
            _not_enough_seats: 'Недостаточно мест для покупки.',
            _order_register_order: 'Ошибка при регистрации заказа. Повторите попытку позже.',
            _form_sent: 'Ваша заявка успешно отправлена.'
        }
    },
    show: function (message, duration = 3) {
        if (message[0] === '_') {
            this.show(this.langs[this.locale][message] || "");
            return;
        }

        alert(message);
    },
    hide: function () {

    }
}

export default _alert;