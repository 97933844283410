require('./bootstrap');

class TourCard {
    fullChain = true;
    template = '';
    journey = '';
    dom = {
        wrapper: null,
        element: null,
        preview: null,
        available_seats: null,
        title: null,
        origin: null,
        destination: null,
        duration: null,
        vehicle: null,
        departure_time: null,
        departure_date: null,
        price: null,
        button: null,
        features_wrapper: null,
    }
    shown = true;
    constructor(wrapper, journey, template = null) {
        this.dom.wrapper = wrapper;
        this.journey = journey;
        this.template = template || this.getDefaultTemplate();
        this.initDom();
        this.setListeners();
        this.fill();
    }
    initDom() {
        this.create();

        if (!this.dom.element) {
            return false;
        }

        this.dom.preview = this.dom.element.querySelector('.tour-card-preview');
        this.dom.available_seats = this.dom.element.querySelector('.available-seats__counter');
        this.dom.title = this.dom.element.querySelector('.tour__title');
        this.dom.origin = this.dom.element.querySelector('.origin__address');
        this.dom.destination = this.dom.element.querySelector('.destination__address');
        this.dom.duration = this.dom.element.querySelector('.duration__time');
        this.dom.vehicle = this.dom.element.querySelector('.vehicle__title');
        this.dom.departure_time = this.dom.element.querySelector('.departure_time');
        this.dom.departure_date = this.dom.element.querySelector('.departure_date');
        this.dom.price = this.dom.element.querySelector('.price');
        this.dom.button = this.dom.element.querySelector('.tour-order__btn');
        this.dom.features_wrapper = this.dom.element.querySelector('.tour-features__wrapper');
    }
    setListeners() {
        if (this.dom.button) {
            this.dom.button.addEventListener('click', e => {
                window.location.href = this.getLink(this.fullChain);
            });
        }
    }
    getDefaultTemplate() {
        let element = document.querySelector('#tour-card__template');
        if (!element) {
            return '';
        }
        return element.innerHTML;
    }
    getLink(fullChain = true) {
        return system.generateJourneyLink(this.journey, fullChain);
    }
    create() {
        let div = document.createElement('div');
        this.dom.wrapper.appendChild(div).outerHTML = this.template;
        return this.dom.element = this.dom.wrapper.lastChild;
    }
    fill(journey = null) {
        this.journey = journey = journey || this.journey;

        if (!this.journey || !this.dom.element) {
            return false;
        }

        this.setText(this.dom.title, journey.aggregator_data.serviceName);
        this.setHref(this.dom.title, this.getLink(true));
        this.setText(this.dom.vehicle, journey.aggregator_data.venueName);
        this.setHref(this.dom.vehicle, this.getVehicleUri(journey));

        let stop = this.getLocalStop(journey);
        this.setText(
            this.dom.origin,
            stop
                ? stop.pagetitle
                : (journey.origin ? journey.origin.title_ru : '')
        );
        this.setHref(this.dom.origin, this.getStopUri(journey));

        this.setText(this.dom.destination, journey.destination ? journey.destination.title_ru : '');
        this.setText(this.dom.available_seats, journey.available_seats);
        this.setText(this.dom.price, this.getPrice(journey));
        this.setText(this.dom.departure_time, Date.parse(journey.departure).toString('HH:mm'));
        this.setText(this.dom.departure_date, system.getPrettyDate(Date.parse(journey.departure)));
        this.setText(
            this.dom.duration,
            journey.duration === 0 ? '-' : Date.today().addSeconds(journey.duration).toString('HH:mm')
        );
        this.loadLineData();
    }
    getVehicleUri(journey) {
        return '/flotiliya/' + system.getVehicleSlug(journey.aggregator_data.venueName);
    }
    getLocalStop(journey) {
        if (typeof local_stops === 'undefined' || !journey.origin) {
            return null;
        }

        return local_stops.data.filter(stop => parseInt(stop['pier.stop_id']) === journey.origin.id)[0] || null;
    }
    getStopUri(journey) {
        let stop = this.getLocalStop(journey);
        return stop ? stop.uri : '/prichalyi';
    }
    show() {
        this.dom.element.classList.remove('d-none');
    }
    hide() {
        this.dom.element.classList.add('d-none');
    }
    toggle() {
        if (this.shown) {
            this.hide();
        } else {
            this.show();
        }
    }
    getPrice(journey = null) {
        this.journey = journey || this.journey;

        if (!this.journey || this.journey.prices.length === 0) {
            return 0;
        }

        return parseInt(this.journey.prices[0].price);
    }
    setHref(element, href) {
        if (element) {
            element.href = href;
        }
    }
    setText(element, text) {
        if (element) {
            element.innerHTML = text;
        }
    }
    setImage(element, src) {
        if (element) {
            element.style.backgroundImage = "url('" + src +"')";
        }
    }
    addFeature(path) {
        if (!this.dom.features_wrapper) {
            return;
        }

        this.dom.features_wrapper.appendChild(
            document.createElement('div')
        ).outerHTML = '<div class="tour-card-preview-feature" style="background-image:url(' + path + ')"></div>';
    }
    loadLineData() {
        let line = this.line();

        if (!line) {
            return;
        }

        this.setImage(this.dom.preview, line.tour_bg_image);
        this.setText(this.dom.title, line.pagetitle);

        if (line.tour_features) {
            line.tour_features.forEach(path => {
                this.addFeature(path);
            })
        }
    }
    line() {
        return system.getLine(this.journey.line_id);
    }
}

export default TourCard;