require("./helpers");
require("datejs");

import system from "./system";
window.system = system;

import _ from "lodash";
window._ = _;

import Schedule from "./schedule";
window.Schedule = Schedule;

import Tour from "./tour";
window.Tour = Tour;