require("./bootstrap");
import api from "./api";
import TourCard from "./tourCard";
import tours from "./tours";

class Schedule {
    fullChain = true
    method = api.journey.list.bind(api)
    template = document.querySelector('#schedule-row__template').innerHTML
    var_prefix = 'schedule-row__'
    element = null
    wrapper_selector = '.tours-schedule-table-body'
    wrapper = null
    panel = {
        _parent: null,
        date_selector: "#schedule-date__flatpickr",
        class: "tours-schedule-nav-item",
        active_class: "active",
        init: function (parent) {
            this._parent = parent;
            this.setListeners();
        },
        setListeners: function () {
            this._parent.element.querySelector('.' + this.class)
                .closest('.row')
                .addEventListener('click', e => {
                    let item = e.target.closest('.' + this.class);
                    if (item) {
                        this.select(item);
                        this._parent.load();
                    }
                });

            this._parent.element.querySelector(this.date_selector).addEventListener('change', e => {
                this._parent.load();
            });

            this._parent.element.querySelector('.next-day-schedule__btn').addEventListener('click', e => {
                let date = this.date();

                if (!this.date()) {
                    this.select(this._parent.element.querySelector('.' + this.class));
                    return;
                }

                this._parent.element.querySelector(this.date_selector).value = date.addDays(1).toString('dd.MM.yyyy');
                this.select(this._parent.element.querySelector(this.date_selector).closest('.' + this.class));

                this._parent.load();
            })
        },
        current: function () {
            return this._parent.element.querySelector('.' + this.class + '.' + this.active_class);
        },
        all: function () {
            return this._parent.element.querySelectorAll('.' + this.class);
        },
        activate: function (element) {
            element.classList.add(this.active_class);
        },
        deactivate: function (element) {
            element.classList.remove(this.active_class);
        },
        select: function (element) {
            this.all().forEach(el => {
                this.deactivate(el);
            })
            this.activate(element);
        },
        date: function () {
            if (!this.current()) {
                return null;
            }

            let date_element = this.current().querySelector('.tours-schedule-nav-item-date');
            if (date_element) {
                return Date.fromDotString(date_element.textContent);
            }

            let date_input = this.current().querySelector(this.date_selector);
            if (date_input) {
                return Date.fromDotString(date_input.value);
            }

            return null;
        }
    }
    config = {
        order: 'departure',
        conditions: null
    }
    tours = tours
    constructor(selector_or_element, config = {}, fullChain = true) {
        if (typeof selector_or_element === 'string') {
            this.element = document.querySelector(selector_or_element);
        } else if (
            selector_or_element instanceof Element
            || selector_or_element instanceof HTMLDocument
        ) {
            this.element = selector_or_element;
        }

        if (!this.element) {
            //TODO: uncomment line below, remove next line
            // return;
            throw new Error('Argument "selector_or_element" must be instance of Element|HTMLDocument or correct selector string');
        }

        this.fullChain = fullChain;

        this.wrapper = this.element.querySelector(this.wrapper_selector);

        if (!this.wrapper) {
            throw new Error('Schedule element has no row wrapper element');
        }

        this.setConfig(config);
        this.panel.init(this);
        this.load();
    }
    setConfig(config) {
        this.config = {
            ...this.config,
            ...config
        }
    }
    load(date, method, callback) {
        this.showLoading();
        method = method || this.method;
        callback = callback || this.loadFromJourneys.bind(this);
        date = date || this.panel.date();
        if (date === null) {
            return false;
        }
        if (date instanceof Date) {
            date = date.toString('yyyy-MM-dd HH:mm:ss');
        }
        method(callback, {
            date_from: date,
            detailed: true,
            order: this.config.order,
            filters: this.config.filters || {}
        })
    }
    loadFromJourneys(journeys) {
        journeys = journeys.filter(journey => {
            return ![5, 29, 44, 45, 46, 62].includes(journey.line_id);
        })

        this.hideLoading();

        journeys.forEach(journey => {
            journey.card = new TourCard(this.wrapper, journey, this.template);
            journey.card.fullChain = this.fullChain;
            this.tours.journeys.push(journey);
        })

        if (this.config.conditions) {
            this.tours.apply([this.config.conditions, {}]);
        }

        if (this.wrapper.querySelectorAll('tr:not(.d-none)').length === 0) {
            this.showEmptyResult();
        } else {
            this.hideEmptyResult();
        }
    }
    getJourneyPrice(journey) {
        if (journey.prices.length === 0) {
            return 0;
        }
        return journey.prices[0].price; //TODO получать цену для взрослого
    }
    showLoading() {
        this.wrapper.innerHTML = '';
        this.hideEmptyResult();
    }
    hideLoading() {

    }
    showEmptyResult() {
        let no_result_row = this.element.querySelector('.tours-schedule-no-results');

        if (no_result_row) {
            no_result_row.classList.remove('d-none');
        }
    }
    hideEmptyResult() {
        let no_result_row = this.element.querySelector('.tours-schedule-no-results');

        if (no_result_row) {
            no_result_row.classList.add('d-none');
        }
    }
}

export default Schedule;