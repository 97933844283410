require('./bootstrap');
import axios from "axios";

axios.defaults.headers.common["ngrok-skip-browser-warning"] = true;

const api = {
    domain: 'https://aggregator.turotdel.com/api/',
    // domain: 'https://94b7-93-190-224-59.ngrok-free.app/api/',
    paths: {
        stop: {
            list: 'v1/stops'
        },
        schedule: {
            list: 'v1/schedule/all'
        },
        journey: {
            list: 'v1/journeys',
            dates: 'v1/journeys/dates'
        },
        line: {
            list: 'v1/lines',
            get: 'v1/lines/'
        },
        seat: {
            list: 'v1/seats/'
        },
        order: {
            register: 'v1/order'
        }
    },
    stops: {
        list: function (callback, params = {}) {
            api.process(
                axios.get(
                    api.domain + api.paths.stop.list,
                    {params}
                ),
                callback
            );
        }
    },
    line: {
        list: function (callback, params) {
            api.process(
                axios.get(
                    api.domain + api.paths.stop.list,
                    {params}
                ),
                callback
            );
        },
        get: function () {
            api.process(
                axios.get(api.domain + api.paths.stop.list + id),
                callback
            );
        }
    },
    schedule: {
        list: function (callback, params = {}) {
            api.process(
                axios.get(
                    api.domain + api.paths.schedule.list,
                    {params}
                ),
                callback
            );
        }
    },
    journey: {
        list: function (callback, params = {}) {
            api.process(
                axios.get(
                    api.domain + api.paths.journey.list,
                    {params}
                ),
                callback
            );
        },
        dates: function (callback, params = {}) {
            console.log(params);
            api.process(
                axios.get(
                    api.domain + api.paths.journey.dates,
                    {params}
                ),
                callback
            );
        }
    },
    seat: {
        list: function (callback, journey_id, cached = false) {
            api.process(
                axios.get(
                    api.domain + api.paths.seat.list + journey_id,
                    // {params: {not_cached: !cached}}
                ),
                callback
            )
        },
        async returnableList(journey_id, cached = false) {
            return await axios.get(api.domain + api.paths.seat.list + journey_id + '?not cached=' + !cached);
        }
    },
    order: {
        register(callback, params) {
            api.process(
                axios.post(
                    api.domain + api.paths.order.register,
                    params
                ),
                callback
            );
        }
    },
    modx: {
        cached_lines: [],
        lines(callback) {
            api.process(
                axios.get('/api/v1/lines'),
                callback
            );
        },
        order: {
            register(callback, params) {
                axios.post(
                    '/ajax/registerOrder.php',
                    params,
                    {headers: {'content-type': 'application/x-www-form-urlencoded'}}
                ).then(response => {
                    console.log(response);
                    if (response.data.status !== 'success') {
                        system.alert('_order_register_order');
                    } else {
                        callback(params);
                    }
                }).catch(reason => {
                    system.alert('_order_register_order');
                });
            }
        },
        lead: {
            create(data) {
                axios.post(
                    '/ajax/createLead.php',
                    data,
                    {headers: {'content-type': 'application/x-www-form-urlencoded'}}
                );
            }
        },
        alert(data) {
            data.url = window.location.href;
            axios.post(
                '/ajax/alert.php',
                data,
                {headers: {'content-type': 'application/x-www-form-urlencoded'}}
            );
        }
    },
    process: function (promise, callback) {
        promise.then(response => {
            // console.log(response);
            callback(response.data.data);
        }).catch(error => {
            api.catch(error);
        });
    },
    catch: function (error) {
        console.log(error);
        system.alert("_whoops");
    }
}

export default api;