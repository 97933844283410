import TourCard from "./tourCard";
import api from "./api";

const tours = {
    journeys: [],
    filters: {
        time: {
            day: ['7:00', '16:00'],
            afternoon: ['16:00', '21:00'],
            night: ['21:00', '7:00']
        }
    },
    dom: {
        wrapper: null
    },
    init: function () {
        this.initDom();
        this.load();
    },
    initDom: function () {
        this.dom.wrapper = document.querySelector('#search-results__wrapper');
    },
    load(date = null) {
        date = date || search.panel.date();

        if (date === null) {
            return false;
        }
        if (date instanceof Date) {
            date = date.toString('yyyy-MM-dd HH:mm:ss');
        }

        this.dom.wrapper.innerHTML = '';
        api.journey.list(this.loadJourneys.bind(this), {
            date_from: date,
            detailed: true,
            order: 'departure',
        })
        this.apply();
    },
    loadJourneys: function(journeys) {
        journeys = journeys.filter(journey => {
            return ![5, 29, 44, 45, 46, 62].includes(journey.line_id);
        })
        journeys.forEach(journey => {
            journey.card = new TourCard(this.dom.wrapper, journey);
            this.journeys.push(journey);
        });
        this.apply();
    },
    showAll: function () {
        this.journeys.forEach(journey => {
            journey.card.show();
        })
    },
    hideAll: function () {
        this.journeys.forEach(journey => {
            journey.card.hide();
        })
    },
    apply: function (conditions = null) {
        conditions = conditions || search.panel.filters.conditions();

        this.showAll();

        conditions.forEach(t_conditions => {
            this.journeys.forEach(journey => {
                this.applyConditionsOnJourney(journey, t_conditions);
            });
        });
    },
    applyConditionsOnJourney: function (journey, conditions) {
        if (!this.hasConditions(journey, conditions)) {
            journey.card.hide();
        }
    },
    hasConditions: function (journey, conditions) {
        if (Object.keys(conditions).length === 0) {
            return true;
        }

        for (let target in conditions) {
            if (this.hasCondition(journey, target, conditions[target])) {
                return true;
            }
        }

        return false;
    },
    hasCondition: function (journey, target, condition) {
        let needle = _.get(journey, target.split('.'));

        if (target === 'aggregator_data.venueName') {
            needle = system.getVehicleSlug(needle);
            if (Array.isArray(condition)) {
                condition.forEach(function(value, idx) {
                    condition[idx] = system.getVehicleSlug(value);
                });
            } else {
                condition = system.getVehicleSlug(condition);
            }
        }

        if (needle !== undefined) {
            return Array.isArray(condition)
                ? condition.includes(needle)
                : needle == condition;
        }

        switch (target) {
            case 'time':
                if (this.filters.time[condition] !== undefined) {
                    return this.isTimeBetween(
                        Date.parse(journey.departure).toString('HH:mm'),
                        this.filters.time[condition][0],
                        this.filters.time[condition][1],
                    )
                }
        }
        return false;
    },
    timeToSeconds(time) {
        let parts = time.split(':');
        return (parseInt(parts[0])*60+parseInt(parts[1]))*60
    },
    isTimeBetween: function (time, from, to) {
        let time_secs = this.timeToSeconds(time);
        let from_secs = this.timeToSeconds(from);
        let to_secs = this.timeToSeconds(to);

        return (time_secs >= from_secs && time_secs < to_secs)
            || (
                from_secs > to_secs
                && (time_secs >= from_secs || time_secs < to_secs)
            );
    }
}

export default tours;